import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { useTranslation } from "react-i18next"
import "../../i18n.js";

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog-banner/blogBanner";
import careerGif from '../images/careers.gif';

const Careers = () => {
  const { t } = useTranslation();

  return (
    <Layout navbarTheme="light" className="bg-blue">
      <Seo title={t("metaCareersTitle")} description={t("metaCareersDescription")} canonical="https://tio.ist/careers/" />

      <div className="mt-28 lg:mt-32">
        <div className="mb-16 lg:mb-24">
          <h1 className="mx-5 md:mx-16 text-6xl md:text-8xl lg:text-9xl font-extrabold text-white">
            {t("workWithUsTitle")}
          </h1>
        {/*   <div className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="ticker z-10">
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
            </div>
            <div className="ticker z-10">
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
              <div className="ticker-item w-auto mr-24 md:mr-40">
                <p>{t("careersTickerTitle")}</p>
              </div>
            </div>
          </div> */}

          <div className="flex flex-row text-white text-6xl md:text-8xl lg:text-9xl font-light italic overflow-hidden">
              <div className="flex flex-row items-center flex-none min-w-full animate-scroll-left">
                  <p className="w-full">{t("careersTickerTitle")}</p>
              </div>
              <div className="flex flex-row items-center flex-none min-w-full animate-scroll-left">
                <p className="w-full">{t("careersTickerTitle")}</p>
              </div>
          </div>
        </div>
      </div>

      <div className="md:flex md:justify-between mx-5 md:mx-16 lg:mx-36 md:space-x-10 lg:space-x-0">
        <div className="w-2/3 md:w-1/3">
          <p className="text-cream font-light text-xl lg:text-3xl whitespace-pre-line">{t("careersDescription")}</p>
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 flex md:block md:self-start justify-end mt-20 lg:mt-0">
          <div className="w-3/4 md:w-full">
            <div className="relative">
              <img src={careerGif} alt={t("applyCTA")} />
              <a href="https://tr.indeed.com/tio-jobs-in-Istanbul" target="_blank" rel="noreferrer" className="group absolute -left-5 md:left-20 lg:left-11 bottom-1/3 inline-block text-cream font-bold text-6xl md:text-8xl lg:text-9xl whitespace-nowrap">
                <span className="border-b-2 border-transparent group-hover:border-white">{t("applyCTA")}</span>{" "}
                <svg className="inline-block w-8 h-8 md:w-10 md:h-16 lg:w-20 lg:h-20 align-top pt-1 lg:pt-4" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.43182 36.522L0 31.0902L22.9318 8.13565H5.70455L5.75 0.726562H35.7727V30.772H28.3182L28.3636 13.5675L5.43182 36.522Z" fill="white" />
                </svg>
              </a>
            </div>
            <a href="https://tr.indeed.com/tio-jobs-in-Istanbul" target="_blank" rel="noreferrer" className="group inline-block text-lightBlue font-light text-lg md:text-xl mt-6"><span className="border-b-2 border-transparent group-hover:border-white">{t("hiringImageCaption")}</span> → →</a>
          </div>
        </div>
      </div>

      <div className="mt-64 lg:mt-80 md:mb-72 lg:mb-96">
        <div className="md:flex md:justify-between mx-6 md:mx-16 lg:mx-auto lg:max-w-[60rem]">
            <div className="mx-auto md:mx-0 w-72 h-[17rem]">
              <StaticImage src="../images/door-lg.png" alt="openings" />
            </div>
            <div className="mt-16 md:mt-0">
              <h2 className="text-white text-6xl md:text-8xl lg:text-9xl">
                <span className="font-bold block">{t("hiringTitle")}</span>
                <span className="italic block">{t("processTitle")}</span>
              </h2>
            </div>
        </div>
        <div className="mx-5 md:mx-3 lg:mx-9 mt-6 md:mt-16 lg:mt-32">
          <ol className="md:flex flex-wrap">
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 p-7">
                <h3 className="font-bold text-white text-xl mb-3">
                  {t("hiringStep1Title")}
                </h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep1Description")}
                </p>
              </div>
            </li>
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 p-7">
                <h3 className="font-bold text-white text-xl mb-3">{t("hiringStep2Title")}</h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep2Description")}
                </p>
              </div>
            </li>
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 p-7">
                <h3 className="font-bold text-white text-xl mb-3">
                  {t("hiringStep3Title")}
                </h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep3Description1")}{" "}<span className="font-bold">{t("hiringStep3Description2")}</span>{" "}
                  {t("hiringStep3Description3")}
                </p>
              </div>
            </li>
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 lg:mx-16 p-7">
                <h3 className="font-bold text-white text-xl mb-3">{t("hiringStep4Title")}</h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep4Description1")}{" "}<span className="font-bold">{t("hiringStep4Description2")}</span>{" "}
                  {t("hiringStep4Description3")}
                </p>
              </div>
            </li>
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 lg:mx-16 p-7">
                <h3 className="font-bold text-white text-xl mb-3">
                  {t("hiringStep5Title")}
                </h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep5Description")}
                </p>
              </div>
            </li>
            <li className="w-full md:w-1/3 my-1 md:my-6">
              <div className="w-72 mx-auto md:mx-0 lg:mx-16 p-7">
                <h3 className="font-bold text-white text-xl mb-3">{t("hiringStep6Title")}</h3>
                <p className="text-white text-xl font-light">
                  {t("hiringStep6Description1")}{" "}<span className="font-bold">{t("hiringStep6Description2")}</span>{" "}
                  {t("hiringStep6Description3")}
                </p>
              </div>
            </li>
          </ol>
        </div>
      </div>

      <div className="mx-6 md:mx-11 lg:mx-16">
        <div className="relative mb-10 md:mb-24 lg:mb-44">
          <div>
            <StaticImage src="../images/tio-led.jpg" alt="workspace" formats={["auto", "webp", "avif"]} />
          </div>
          <div className="absolute left-5 md:left-20 lg:left-24 bottom-1/4">
            <p className="text-cream font-bold text-5xl md:text-8xl lg:text-9xl whitespace-pre-line">{t("open")} <br /> <span className="italic font-light">{t("positions")}</span>{" "}
              <svg className="inline-block pl-3 md:pl-0 lg:pl-3 w-10 h-10 lg:w-20 lg:h-20" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M68.8182 12.0909L59.2727 69.7273H1.72727L3.27273 59.9091H44L0 7.09091L8.27273 0L52.2727 52.8182L58.9091 12.0909H68.8182Z" fill="#FBF7EE" />
              </svg>
            </p>
          </div>
        </div>
        <div className="md:max-w-md lg:max-w-2xl mx-auto flex lg:justify-end">
          <ul className="md:max-w-md lg:max-w-xl -space-y-4 md:-space-y-3 lg:space-y-10">
            <li>
              <a
                href="https://tr.indeed.com/cmp/Tio-Bili%C5%9Fim-A.%C5%9F./jobs?jk=de264542405145a6&start=0&clearPrefilter=1"
                target="_blank"
                rel="noreferrer"
                className="py-5 inline-block text-white text-xl lg:text-3xl font-light"
              >
                <span className="border-b-2 border-white">{t("productManagement")}</span>
              </a>
            </li>
            <li>
              <a
                href="https://tr.indeed.com/cmp/Tio-Bili%C5%9Fim-A.%C5%9F./jobs?jk=8a6030d105447250&start=0&clearPrefilter=1"
                target="_blank"
                rel="noreferrer"
                className="py-5 inline-block text-white text-xl lg:text-3xl font-light"
              >
                <span className="border-b-2 border-white">{t("productSpecialist")}</span>
              </a>
            </li>
            <li>
              <a
                href="https://tr.indeed.com/cmp/Tio-Bili%C5%9Fim-A.%C5%9F./jobs?jk=6477fa6261eb83d5&start=0&clearPrefilter=1"
                target="_blank"
                rel="noreferrer"
                className="py-5 inline-block text-white text-xl lg:text-3xl font-light"
              >
                <span className="border-b-2 border-white">{t("productDeveloper")}</span>
              </a>
            </li>
            <li>
              <a
                href="https://tr.indeed.com/cmp/Tio-Bili%C5%9Fim-A.%C5%9F./jobs?jk=d23d2b41fa4dc4a7&start=0&clearPrefilter=1"
                target="_blank"
                rel="noreferrer"
                className="py-5 inline-block text-white text-xl lg:text-3xl font-light"
              >
                <span className="border-b-2 border-white">{t("productDesigner")}</span>
              </a>
            </li>
            <li>
              <a
                href="https://tr.indeed.com/cmp/Tio-Bili%C5%9Fim-A.%C5%9F./jobs?jk=03665801e687e8a1&start=0&clearPrefilter=1"
                target="_blank"
                rel="noreferrer"
                className="py-5 inline-block text-white text-xl lg:text-3xl font-light"
              >
                <span className="border-b-2 border-white">{t("projectManager")}</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="my-4 md:my-0 lg:my-36 mb-64 md:mb-48 py-12 md:pl-28 flex justify-center md:block">
          <a href="https://tr.indeed.com/tio-jobs-in-Istanbul" target="_blank" rel="noreferrer" className="group inline-block text-cream font-bold text-6xl md:text-8xl lg:text-9xl">
            <span className="border-b-2 border-transparent group-hover:border-white">{t("applyCTA")}</span>{" "}
            <svg className="inline-block w-8 h-8 md:w-10 md:h-16 lg:w-20 lg:h-20 align-top pt-1 lg:pt-4" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.43182 36.522L0 31.0902L22.9318 8.13565H5.70455L5.75 0.726562H35.7727V30.772H28.3182L28.3636 13.5675L5.43182 36.522Z" fill="white" />
            </svg>
          </a>
        </div>
      </div>

      <BlogBanner />
    </Layout>
  )
}

export default Careers
